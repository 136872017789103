import { useCallback, useEffect, useState } from "react";
import {iFrameHost, PropsFromCartRedux} from "./BuildOwn";
import { PropsFromScreenManager } from "../../types";
import { nanoid } from 'nanoid';
import {getLowQualityRender} from "../../Utils/getLowQualityRender";

const useBuildOwn = (props:PropsFromScreenManager & PropsFromCartRedux) => {
  const {instanceData: { screenManager },ring,footer,setCartImageSuccess,setCartThumbImageSuccess} = props
  const [isIcon, setIsIcon] = useState(true);
  const [viewSrc, setViewSrc] = useState("");
  const [iframeLoadActionCount, setIframeLoadActionCount] = useState<number>(0);
  const currentIcon = !isIcon ? "hk-AR" : "hk-image";
  const [image,setImage] = useState<string[]>([]);
  const [thumbImage,setThumbImage] = useState<string[]>([]);

  useEffect(() => {
    setViewSrc(iFrameHost);
  }, []);

  /*useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(ring.options).length > 0) {
        const { Ring_Style, Ring_Crown, Center_Diamond_Size, Ring_Side, Diamond_Shape, Color, Single_First_Band, Double_First_Band, Double_Second_Band, Wedding_Band } = ring.options;
        if (footer.styleID && Ring_Crown && Diamond_Shape && Center_Diamond_Size && Ring_Side) {
          const imageSKU = `${Ring_Style}-${Ring_Crown}-${Ring_Side}`;
          const lowQuality = await getLowQualityRender(Ring_Style, Ring_Crown, Ring_Side);
          setImage([]);
          setThumbImage([]);

          const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-${lowQuality && lowQuality[imageSKU]? Center_Diamond_Size :'200'}-${Ring_Side}` : `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${lowQuality && lowQuality[imageSKU]? Center_Diamond_Size :'200'}-${Ring_Side}`;
          const uniqueName = footer.styleID.includes('RP') ? `${footer.styleID}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}` : `${footer.styleID}${Ring_Style}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}${Color}${Color}${(Single_First_Band || Double_First_Band) ? `${Single_First_Band || Double_First_Band}` : ''}${Double_Second_Band ? `${Double_Second_Band}` : ''}${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}`;

          ["V1", "V2", "V3", "V4"].forEach((view: string) => {
            const imagePath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Band || Double_First_Band) ? `-${Single_First_Band || Double_First_Band}` : ''}${Double_Second_Band ? `-${Double_Second_Band}` : ''}/${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}/${view}/2000/fafafa/${lowQuality && lowQuality[imageSKU]?'lq/':'hq/'}${uniqueName}${view}2000.jpg`;
            const thumbPath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Band || Double_First_Band) ? `-${Single_First_Band || Double_First_Band}` : ''}${Double_Second_Band ? `-${Double_Second_Band}` : ''}/${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}/${view}/150/fafafa/${lowQuality && lowQuality[imageSKU]?'lq/':'hq/'}${uniqueName}${view}150.jpg`;
            setImage((prev: string[]) => {
              return [...prev, imagePath];
            });
            setThumbImage((prev: string[]) => {
              return [...prev, thumbPath];
            });
          });
        }
      }
    }

    fetchData(); // Call the async function immediately

  }, [ring.options["Ring_Style"],ring.options["Ring_Crown"],ring.options["Diamond_Shape"],ring.options["Center_Diamond_Size"],ring.options["Ring_Side"],ring.options["Metal"],ring.options["Color"],ring.options["Diamond_Type"],ring.options["Wedding_Band"],ring.options["Single_First_Band"],ring.options["Double_Second_Band"],ring.options["Double_First_Band"]]);*/

  useEffect(()=>{
    setCartImageSuccess(image);
  },[image])
  useEffect(()=>{
    setCartThumbImageSuccess(thumbImage);
  },[thumbImage])
  const handleChangeIcon = useCallback(() => {
    setIsIcon((icon) => !icon);
  }, [setIsIcon]);

  useEffect(() => {
    (screenManager as any).props.setStateManagerData(
      "currentScreen",
      "Select Settings screen"
    );
    (screenManager as any).props.setStateManagerData(
      "currentView",
      "Select Settings Main View"
    );
  }, []);

  return {
    img:image,
    thumbImage,
    viewSrc,
    currentIcon,
    handleChangeIcon,
    isIcon,
    iframeLoadActionCount,
    setIframeLoadActionCount,
  };
};
export default useBuildOwn;
