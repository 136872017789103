import Icon from "../../../../../Icons/Icon";
import { MRT_Row, MRT_RowData, MRT_TableInstance } from "../../types";
import React from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}
export const MRT_ExpandButton = <TData extends MRT_RowData>({
  row,
  table,
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { ExpandMoreIcon },
      localization,
      muiExpandButtonProps,
      renderDetailPanel,
    },
  } = table;
  const { density } = getState();

  const iconButtonProps =
    muiExpandButtonProps instanceof Function
      ? muiExpandButtonProps({ table, row })
      : muiExpandButtonProps;

  const canExpand = row.getCanExpand();
  const isExpanded = row.original.isExpanded;

  const handleToggleExpand = (event: MouseEvent) => {
    event.stopPropagation();
    row.toggleExpanded();
    iconButtonProps?.onClick?.(event);
  };

  return (
      <>
        <div className="headless_tooltip_bottom"
             data-tip={
               iconButtonProps?.title ?? isExpanded
                   ? localization.collapse
                   : localization.expand
             }>
          <button
              {...iconButtonProps}
              aria-label={localization.expand}
              disabled={!canExpand && !renderDetailPanel}
              className={classNames(
                  `headless_expandBtn-tooltipBtn`,
                  density === "compact"
                      ? "headless_hw-175"
                      : "headless_hw-225"
              )}
              // onClick={handleToggleExpand}
          >
            {iconButtonProps?.children ?? (
                // <ExpandMoreIcon
                //     className={"h-4 w-4"}
                //     style={{
                //       transform: `rotate(${
                //           !canExpand && !renderDetailPanel ? -90 : isExpanded ? -180 : 0
                //       }deg)`,
                //       transition: "transform 150ms",
                //     }}
                // />
                <span style={{transform: `rotate(${
                            !canExpand && !renderDetailPanel ? -90 : isExpanded ? -180 : 0
                        }deg)`,
                        transition: "transform 150ms" }}>
                    <Icon name={'hk-chevron-down_M'} className={!canExpand && !renderDetailPanel ? isExpanded : '' } />
                </span>
            )}
          </button>
        </div>
      </>
  );
};
