import React from "react";
import { MRT_TableContainer } from "./MRT_TableContainer";
import { parseFromValuesOrFunc } from "../../column.utils";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { MRT_BottomToolbar, MRT_TopToolbar } from "../toolbar";

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export const MRT_TablePaper = <TData extends MRT_RowData>({
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: {
      enableBottomToolbar,
      enableTopToolbar,
      muiTablePaperProps,
      renderBottomToolbar,
      renderTopToolbar,
    },
    refs: { tablePaperRef },
  } = table;
  const { isFullScreen,isLoading } = getState();

  const tablePaperProps = {
    ...parseFromValuesOrFunc(muiTablePaperProps, { table }),
    ...rest,
  };

  return (
    <div
      className={classNames(`headless_table-paper ${isLoading ? 'headless_overflow-hidden' : ''}`)}
      {...tablePaperProps}
      ref={(ref) => {
        tablePaperRef.current = ref as HTMLDivElement;
        if (tablePaperProps?.ref) {
          tablePaperProps.ref.current = ref;
        }
      }}
      style={{
        ...tablePaperProps?.style,
        ...(isFullScreen
          ? {
              bottom: 0,
              height: "100vh",
              left: 0,
              margin: 0,
              maxHeight: "100vh",
              maxWidth: "100vw",
              padding: 0,
              position: "fixed",
              right: 0,
              top: 0,
              width: "100vw",
              zIndex: 10,
            }
          : {}),
      }}
    >
      {enableTopToolbar &&
        (renderTopToolbar instanceof Function
          ? renderTopToolbar({ table })
          : renderTopToolbar ?? <MRT_TopToolbar table={table} />)}
      <MRT_TableContainer table={table} />
      {enableBottomToolbar &&
        (renderBottomToolbar instanceof Function
          ? renderBottomToolbar({ table })
          : renderBottomToolbar ?? <MRT_BottomToolbar table={table} />)}
    </div>
  );
};
