import React, { FC } from "react";
import IcoMoon from "react-icomoon";
import iconSet from "./selection.json";
import { IconProps } from "../../types";

const Vector: FC<IconProps> = ({
  name,
  className,
  fontSize = "2.5em",
  color,
}) => {
  return (
    <IcoMoon
      iconSet={iconSet}
      icon={name}
      size={fontSize}
      color={color}
      className={className}
    />
  );
};

export default Vector;
