import React from "react";
import { MRT_Row, MRT_RowData, MRT_TableInstance } from "../../types";
import { RefObject } from "react";
import { VirtualItem } from "@tanstack/react-virtual";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  parentRowRef: RefObject<HTMLTableRowElement>;
  row: MRT_Row<TData>;
  rowIndex: number;
  table: MRT_TableInstance<TData>;
  virtualRow?: VirtualItem | MRT_Row<TData>;
}

export const MRT_TableDetailPanel = <TData extends MRT_RowData>({
  parentRowRef,
  row,
  rowIndex,
  table,
  virtualRow,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    getVisibleLeafColumns,
    options: {
      layoutMode,
      muiDetailPanelProps,
      muiTableBodyRowProps,
      renderDetailPanel,
    },
  } = table;
  const { isLoading } = getState();

  const tableRowProps = muiTableBodyRowProps
    ? muiTableBodyRowProps({
        isDetailPanel: true,
        row,
        staticRowIndex: rowIndex,
        table,
      })
    : muiTableBodyRowProps;

  const tableCellProps = muiDetailPanelProps
    ? muiDetailPanelProps({ row, table })
    : muiDetailPanelProps;

  return (
    <tr
      className={classNames(
        layoutMode === "grid" ? "headless_flex" : "headless_table-row",
        virtualRow
          ? `translate-y-[${
              "start" in virtualRow ? virtualRow?.start : 0
            }px] headless_z-2 absolute top-[${
              parentRowRef.current?.getBoundingClientRect()?.height
            }px]`
          : "",
        `headless_w-full`
      )}
      {...tableRowProps}
    >
      <td
        className={classNames(
          virtualRow ? `bg-content1/[0.06]` : "",
          !row.original.isExpanded ? `border-0` : ``,
          layoutMode === "grid" ? `headless_flex` : `table-cell`,
            row.original.isExpanded ? `headless_py-4` : ``,
          `headless_renderDetail-td w-[${table.getTotalSize()}px],`
        )}
        colSpan={getVisibleLeafColumns().length}
        {...tableCellProps}
      >
        {renderDetailPanel && row.original.isExpanded && (
          <>
            <div className={"headless_renderDetail"}>
              <div className="headless_renderDetail-child">
                {!isLoading && renderDetailPanel({ row, table })}
              </div>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};
