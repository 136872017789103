import React from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/20/solid";
import { MRT_RowData, MRT_TableInstance } from "../../types";

interface Props<TData extends MRT_RowData> {
  position?: "bottom" | "top";
  table: MRT_TableInstance<TData>;
}
export const MRT_TablePagination = <TData extends MRT_RowData>({
  position = "bottom",
  table,
}: Props<TData>) => {
  const { getState } = table;
  const {
    pagination: { pageSize = 10, pageIndex = 0 },
    paginationArray
  } = getState();
 
  return (
    <React.Fragment>
      {pageSize < table.getPrePaginationRowModel().rows.length && (
        <div className={"headless_pagination-main"}>
          <div className="headless_pagination-child1">
            <div className={"headless_flex-gap"}>
              <span className={""}>Rows per page</span>
              <select className="headless_pagination-select" defaultValue={pageSize} onChange={(e)=>{table.setPageSize(Number(e.target.value))}}>
              {paginationArray.map((pageSize) => (
                     <option >{pageSize}</option>
                  ))}
              </select>
            </div>
            <span className="headless_pagination-page">
              {pageIndex + 1}-{pageSize} of{" "}
              {table.getPrePaginationRowModel().rows.length}
            </span>
            <div className={"headless_flex-center"}>
              <button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronDoubleLeftIcon height={24} width={24} />
              </button>
              <button
               
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeftIcon height={24} width={24} />
              </button>
              <button
               
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRightIcon height={24} width={24} />
              </button>
              <button
               
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <ChevronDoubleRightIcon height={24} width={24} />
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
