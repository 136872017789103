import classes from "./InputSlider.module.css";
import Slider from "rc-slider";
import React, {FC} from "react";
import useInputSlider from "./useInputSlider";
import {MultiRangeSliderProps} from "../../types";
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";

interface InputRangeSliderProps extends MultiRangeSliderProps {
    keyName: string;
    step?:number;
    rangeValue?: string[];
    handleFilterType: (value: string, key: string) => void;
    setChangeMinMaxValue: (value: boolean) => {
        type : string;
        payload : boolean;
    };
}

const InputRangeSlider: FC<InputRangeSliderProps> = (props) => {
    const {
      step,
      keyName,
      isShowDollar
    } = props ;

    const {
        value,
        isLeftFocus,
        isRightFocus,
        handleChange,
        handleRightBlur,
        handleRightFocus,
        handleLeftFocus,
        handleLeftBlur,
        handleLeftInputRange,
        handleRightInputRange,
        handleChangeComplete,
        handleLeftBlurWithShowDollar,
        handleRightBlurWithShowDollar,
        minValue,
        maxValue
    } = useInputSlider(props);

    return (
        <>
            <div className={`${classes.inputRangeSlider} ${minValue === maxValue ? classes.minMaxSame : ''}`}>
                <Slider
                    range
                    allowCross={false}
                    min={minValue}
                    max={maxValue}
                    //@ts-ignore
                    value={value as number[]}
                    step={step}
                    draggableTrack={false}
                    handleStyle={{borderColor: "#4a4a4a", backgroundColor: "#fff"}}
                    trackStyle={{backgroundColor: "#4a4a4a"}}
                    onChange={handleChange}
                    onChangeComplete={(val) => handleChangeComplete(val, keyName)}
                />
                {value ? (
                    <div className={classes.slider_inputs}>
                        <input
                            className={classes.slider_leftValue}
                            onFocus={isShowDollar ? handleLeftFocus : () => {
                            }}
                            onBlur={isShowDollar ? ()=>handleLeftBlurWithShowDollar(keyName) : () =>handleLeftBlur(keyName)}
                            type={"text"}
                            value={!isLeftFocus && isShowDollar ? `$${ToLocalStringConvert(value[0] as number | string)}` : ToLocalStringConvert(value[0] as number | string)}
                            onChange={(e) => handleLeftInputRange(e)}
                        />
                        <input
                            type={"text"}
                            onFocus={isShowDollar ? handleRightFocus : () => {
                            }}
                            onBlur={isShowDollar ? ()=>handleRightBlurWithShowDollar(keyName) : ()=>handleRightBlur(keyName)}
                            className={classes.slider_rightValue}
                            value={!isRightFocus && isShowDollar ? `$${ToLocalStringConvert(value[1] as number | string)}` : ToLocalStringConvert(value[1] as number | string)}
                            onChange={(e) => handleRightInputRange(e)}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default InputRangeSlider;
