import React, {FC} from "react";
import classes from "./Label.module.css";
import {LabelProps} from "../../types";
import Tooltip from "../Tooltip";

const Label: FC<LabelProps> = (props) => {
    const {children, text, shapeText, optionsText,endSideContent,page,tooltipPadding} = props;
    let TooltipFilter:string[] =['Diamond Price','Ring Style','Ring Side','Center Diamond Size','Setting Price'];
    if(page === 'pdpBuilder'){
        TooltipFilter = [...TooltipFilter,'Color','First Band Color','Second Band Color','First Band Metal','Second Band Metal'];
    }
    return (
        <div className={`${classes.root} labelRoot ${text.replace(/ /g,'').toLowerCase()}`}>
            <div className={classes.label_wrap}>
                <span className={classes.label_name}>{text}</span>
                {optionsText}
                {
                    !(TooltipFilter.includes(text)) ? (<Tooltip title={text} page={page} tooltipPadding={tooltipPadding}/>):('')
                }
                {shapeText ? <span className={classes.swatchText}>{shapeText}</span> : ''}
                {endSideContent}
            </div>
            {children}
        </div>
    );
};

export default Label;
