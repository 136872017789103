import FilterConstant from '../constant/FilterConstant';

export const setFilter = (key: string, value: string | string[] | boolean) => ({
    type: FilterConstant.SET_FILTER,
    payload: { key, value },
});

export const removeFilterKey = (key: string) => ({
    type: FilterConstant.REMOVE_FILTER_KEY,
    payload: {key},
});

export const resetFilters = () => ({
    type: FilterConstant.RESET_FILTER_KEY,
});

export const setFilterMinMaxProperty = (value :{[key: string] : {[key:string] : number}}) => ({
    type: FilterConstant.FILTER_MIN_MAX_PROPERTY,
    payload: value
})

export const setChangeMinMaxValue = (value : boolean) => ({
    type: FilterConstant.SET_MIN_MAX_VALUE_CHANGE,
    payload: value
});

export const setInitialFilter = () => ({
    type: FilterConstant.FILTER_INITIAL_STATE
});

export const setInitialFilterAfterRingOptions = () => ({
    type: FilterConstant.FILTER_INITIAL_STATE_AFTER_RING_OPTIONS
});

export const setAfterRingOptions = (value : {[key:string] : string | string[]}) =>({
    type: FilterConstant.SET_AFTER_RING_OPTIONS,
    payload: value
})
