import {useRef} from "react";
import Slider from "react-slick";

const useMainImageSlider = () => {
    const sliderForRef = useRef<Slider>(null);
    const sliderNavRef = useRef<Slider>(null);
    return {
        sliderForRef,
        sliderNavRef
    }
}
export default useMainImageSlider;
