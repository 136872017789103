import RingConstant from "../constant/RingConstant";
import {extraDataType} from "../types";

export const setRingOptions  = (key: string, value: string | string[]) => ({
    type: RingConstant.RING_SELECTED_OPTIONS,
    payload: { key, value },
});

export const setOptionsData = (value: any) => ({
    type: RingConstant.RING_OPTIONS_DATA,
    payload: value,
});

export const setOptionsLoading = () => ({
    type: RingConstant.RING_OPTIONS_LOADING,
});
export const setOptionsFail = (error : unknown) => ({
    type: RingConstant.RING_OPTIONS_FAIL,
    payload: error,
});

export const setOptionsDefault =()=>({
    type:RingConstant.RING_OPTIONS_DEFAULT
})

export const setSelectedDiamondData = (value: {[key : string]: string}) => ({
    type: RingConstant.SET_SELECTED_DIAMOND_DATA,
    payload: value,
});

export const setSelectedDiamondLoading = () => ({
    type: RingConstant.SET_SELECTED_DIAMOND_LOADING,
});
export const setSelectedDiamondFail = (error : unknown) => ({
    type: RingConstant.SET_SELECTED_DIAMOND_FAIL,
    payload: error,
});
export const setInitialOption = () => ({
    type: RingConstant.SET_INITIAL_RING_OPTIONS,
});
export const setSelectedRingDetails = (key: string, value: string) => ({
    type: RingConstant.SELECTED_RING_DETAILS,
    payload : {key,value}
});
export const setBackDiamondDetails = (val : boolean) => ({
    type: RingConstant.BACK_DIAMOND_SELECTION_OPTIONS,
    payload: val
});
export const setRemoveRingOption = (key:string) => ({
    type: RingConstant.SET_REMOVE_RING_OPTION,
    payload : key
});
export const setBandTabIndex = (key: number) => ({
    type: RingConstant.SET_BAND_TAB_INDEX,
    payload : key
})

export const setJourneyStartedFrom = (value:string) =>({
    type:RingConstant.SET_JOURNEY_STARTED_FROM,
    payload:value
})
export const setPriceLoading = () => ({
    type: RingConstant.SET_RING_PRICE_LOADING,
})
export const setRingPrice = (value: { [key:string]:number }) => ({
    type: RingConstant.SET_RING_PRICE,
    payload: value
})
export const setRingPriceError = (error : unknown) => ({
    type: RingConstant.SET_RING_PRICE_ERROR,
    payload: error
})
export const setRingExtraData = (value: extraDataType) => ({
    type: RingConstant.RING_EXTRA_DATA,
    payload: value,
});
