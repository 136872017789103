export const getLowQualityRender = async(Ring_Style: string, Ring_Crown: string, Ring_Side: string) => {
    try {
        const lowQualityRender = process.env.REACT_APP_LOW_QUALITY_RENDER;
        const response = await fetch(`${lowQualityRender}?sku=${Ring_Style}-${Ring_Crown}-${Ring_Side}`);
        if (!response.ok) {
            return ;
        }
        const res = await response.json();
        return res;
    }catch (error) {
        console.log(error,'error');
    }
}
