import React, {FC, useEffect} from "react";
import classes from "./diamondFilterContent.module.css";
import Label from "../Label/Label";
import Button from "../Button";
import SlickSlider from "../Slider";
import InputRangeSlider from "../InputSlider";
import LabelRangeSlider from "../LabelRangeSlider";
import useDiamondFilterContent from "./useDiamondFilterConent";
import {FilterContentType} from "../../types";
import {RootState} from "../../reducers/RootReducer";
import {removeFilterKey, setFilter, setChangeMinMaxValue} from "../../action/FilterAction";
import {setPriceLoading, setRingPrice, setRingPriceError,setRingOptions} from "../../action/RingAction";
import {connect, ConnectedProps} from "react-redux";
import {DiamondShapeContent} from "../../Utils/commonData";

const mapStateToProps = (state: RootState) => ({
    filter: state.filter,
    ring: state.ring,
    footer: state.footer
});

const mapDispatchToProps = {
    setFilter,
    removeFilterKey,
    setChangeMinMaxValue,
    setPriceLoading,
    setRingPrice,
    setRingPriceError,
    setRingOptions
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromDiamondFilterContentRedux = ConnectedProps<typeof connector>;

const DiamondFilterContent: FC<FilterContentType> = ({   ring,
                                                         setChangeMinMaxValue,
                                                         source,
                                                         setFilter,
                                                         removeFilterKey,
                                                         filter,
                                                         color,
                                                         clarity,
                                                         cut,
                                                         fluorecence,
                                                         polish,
                                                         symmetry,
                                                         footer,
                                                         setPriceLoading,
                                                         setRingPrice,
                                                         setRingPriceError,
                                                         setRingOptions
                                                     }) => {
    const {handleFilterType,SCS, setSCS,tooltipPadding} =
      useDiamondFilterContent({
        ring,
        footer,
        setPriceLoading,
        setRingPrice,
        setRingPriceError,
        setFilter,
        removeFilterKey,
        setRingOptions
      });
    const rpid = JSON.parse(localStorage.getItem('rpid') as string);
    const builder_mode = JSON.parse(localStorage.getItem('builder_mode') as string);
    const uuid = JSON.stringify(localStorage.getItem('design_uuid') as string);
    const isEditBuilder = rpid  && uuid && builder_mode === "edit" ;
    return (
        <>{filter.minMaxValue && Object.keys(filter.minMaxValue).length > 0 ? <>
            {/*{!filter.afterRingSelectedOptions.source && */}<div className={classes.items}>
                <Label text={"Center Diamond Type"}>
                    {source.map((name) => {
                        return (
                            <Button
                                key={name}
                                type="button"
                                className={classes.button}
                                buttonType={
                                    filter.options?.source && (name === 'Natural' ? 'GNU' : 'LGN') === filter.options.source
                                        ? "active"
                                        : undefined
                                }
                                isDisabled={!!ring.extraData.Label && !["Generic"].includes(ring.extraData.Label)}
                                onClick={() => {
                                    setChangeMinMaxValue(true);
                                    if(name === 'Natural'){
                                        removeFilterKey("scs");
                                    }else {
                                        handleFilterType(false,"scs");
                                        setSCS(false);
                                    }
                                    if (Object.keys(ring.options).length < 1 && filter.options.source === (name === 'Natural' ? 'GNU' : 'LGN')) {
                                        return removeFilterKey("source");
                                    }
                                    handleFilterType((name === 'Natural' ? 'GNU' : 'LGN'), "source")
                                    // removeFilterKey("lab");
                                }
                                }
                            >
                                <span className="text-padding-top-7">{name}</span>
                            </Button>
                        );
                    })}
                </Label>
            </div>
          {/*}*/}
            {/*{!filter.afterRingSelectedOptions.shape && */}
              <div className={classes.items}>
                  <Label text={"Center Diamond Shape"}>
                      <SlickSlider
                          contents={DiamondShapeContent}
                          removeFilterKey={removeFilterKey}
                          handleFilterType={handleFilterType}
                          filter={filter.options}
                          labelKey={"shape"}
                          setChangeMinMaxValue={setChangeMinMaxValue}
                          isMultiSelect={false}
                          isSingleEnable={false}
                      />
                  </Label>
              </div>
          {/*}*/}
            {/*{!filter.afterRingSelectedOptions.carat &&*/} <div className={classes.items}>
                <Label text={"Carat"} tooltipPadding={tooltipPadding["carat"]}>
                    <InputRangeSlider
                        min={filter.afterRingSelectedOptions.carat ? 0.675 :+filter.minMaxValue.carat.min_value as number}
                        max={filter.afterRingSelectedOptions.carat ? 6 :+filter.minMaxValue.carat.max_value as number}
                        rangeValue={isEditBuilder ? (filter.options.carat as string)?.split('-') : ((filter.afterRingSelectedOptions.carat as string)?.split('-') || (filter.options.carat as string)?.split('-'))}
                        step={0.1}
                        isShowDollar={false}
                        keyName={"carat"}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        options={filter.options}
                        isReset={filter.isReset}
                    />
                </Label>
            </div>{/*}*/}
            <div className={classes.items}>
                <Label text={"Diamond Price"}>
                    <InputRangeSlider
                        min={+filter.minMaxValue.b2c_price.min_value as number}
                        max={+filter.minMaxValue.b2c_price.max_value as number}
                        rangeValue={(filter.options.diamond_price as string)?.split('-')}
                        step={5}
                        isShowDollar={true}
                        keyName={"diamond_price"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        options={filter.options}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        isReset={filter.isReset}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Color"} tooltipPadding={tooltipPadding["color"]}>
                    <LabelRangeSlider
                        mark={color}
                        keyName={"color"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        filter={filter}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        rangeValue={(filter.options.color as string)?.split('-')}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Cut"}>
                    <LabelRangeSlider
                        mark={cut}
                        keyName={"cut"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        filter={filter}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        rangeValue={(filter.options.cut as string)?.split('-')}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Clarity"} tooltipPadding={tooltipPadding["clarity"]}>
                    <LabelRangeSlider
                        mark={clarity}
                        keyName={"clarity"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        filter={filter}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        rangeValue={(filter.options.clarity as string)?.split('-')}

                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Certification"}
                       endSideContent={
                           filter.options?.source !== "GNU" ? <div className={`${classes.checkmark} cursor-pointer`} onClick={()=>{
                               setSCS(scs => !scs);
                               handleFilterType(!SCS,'scs' )}}>
                               <input type={"checkbox"}
                                      id={'scs-007'}
                                      checked={SCS}
                                      readOnly={true}
                               />
                               <Label text={"SCS-007"}>
                                    <label htmlFor={'scs-007'} className={'cursor-pointer'}/>
                               </Label>
                           </div> : null
                       }
                >
                    {["GIA","IGI", "GCAL"].map((name) => {
                        const findLab = Array.isArray(filter.options?.lab) && filter.options?.lab.find(f => f === name.toUpperCase());
                        return (
                            <Button
                                key={name}
                                type="button"
                                className={classes.button}
                                buttonType={
                                    name === findLab
                                        ? "active"
                                        : undefined
                                }
                                // isDisabled={filter.options.source === 'GNU' ? name === "GIA"  : filter.options.source === "LGN" ?  ["IGI", "GCAL"].includes(name) : undefined}
                                onClick={() => {
                                    setChangeMinMaxValue(false);
                                    if (Array.isArray(filter.options?.lab) && findLab) {
                                        const filterLab = filter.options?.lab.filter(f => f !== name.toUpperCase());
                                        if (filterLab.length === 0) {
                                            return removeFilterKey("lab")
                                        }
                                        return handleFilterType(filterLab, "lab")
                                    }
                                    if (filter.options?.lab) {
                                        handleFilterType([...filter.options?.lab as string | string[], name.toUpperCase()], "lab")
                                    } else {
                                        handleFilterType([name.toUpperCase()], "lab")
                                    }
                                }}
                            >
                                <span className="text-padding-top-7">{name}</span>
                            </Button>
                        );
                    })}
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"L:W Ratio"}>
                    <InputRangeSlider
                        min={+filter.minMaxValue.ratio.min_value as number}
                        max={+filter.minMaxValue.ratio.max_value as number}
                        step={0.1}
                        isShowDollar={false}
                        keyName={"ratio"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        options={filter.options}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        isReset={filter.isReset}
                        rangeValue={(filter.options.ratio as string)?.split('-')}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Fluorescence"}>
                    <LabelRangeSlider
                        mark={fluorecence}
                        keyName={"fluorecence"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        filter={filter}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        rangeValue={(filter.options.fluorecence as string)?.split('-')}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Polish"}>
                    <LabelRangeSlider
                        mark={polish}
                        keyName={"polish"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        filter={filter}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        rangeValue={(filter.options.polish as string)?.split('-')}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Symmetry"}>
                    <LabelRangeSlider
                        mark={symmetry}
                        keyName={"symmetry"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        filter={filter}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        rangeValue={(filter.options.symmetry as string)?.split('-')}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Table (%)"}>
                    <InputRangeSlider
                        min={+filter.minMaxValue.tbl.min_value as number}
                        max={+filter.minMaxValue.tbl.max_value as number}
                        isShowDollar={false}
                        step={1}
                        keyName={"table_per"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        options={filter.options}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        isReset={filter.isReset}
                        rangeValue={(filter.options.tbl as string)?.split('-')}
                    />
                </Label>
            </div>
            <div className={classes.items}>
                <Label text={"Depth (%)"}>
                    <InputRangeSlider
                        min={+filter.minMaxValue.depth.min_value as number}
                        max={+filter.minMaxValue.depth.max_value as number}
                        isShowDollar={false}
                        step={1}
                        keyName={"depth"}
                        handleFilterType={handleFilterType}
                        removeFilterKey={removeFilterKey}
                        options={filter.options}
                        setChangeMinMaxValue={setChangeMinMaxValue}
                        isReset={filter.isReset}
                        rangeValue={(filter.options.depth as string)?.split('-')}
                    />
                </Label>
            </div>
        </> : null}
        </>
    )
}
export default connector(DiamondFilterContent);
