import { useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {PropsFromScreenManager, UseHeaderTabType} from "../../../types";

const UseHeaderTab = ({diamond,ring,footer,setHeaderTabToSetting,setInitialOption,setDiamondTabIconTrue,setHeaderTabDefault,setSettingTabIconTrue,setIsShowFooter,instanceData:{screenManager},setInitialStartWithSettingData,setInitialDiamondOption,setBothTabIconTrue}:UseHeaderTabType & PropsFromScreenManager) => {
    const navigate = useNavigate();

    const setCenterTabData =(URLPath:string[])=>{
        if(footer.toggle){
            if(URLPath.includes('#diamond')){
                setBothTabIconTrue(['SELECT SETTING','SELECT DIAMOND']);
            }else{
                setBothTabIconTrue(['SELECT DIAMOND','SELECT SETTING']);
            }
        }else{
            if(URLPath.includes('#diamond')){
                if(!(Object.keys(ring.options).length >0)){
                    setHeaderTabDefault();
                }else{
                    setSettingTabIconTrue();
                }
            }else if(URLPath.includes('#setting')){
                if(URLPath.includes('diamondId')){
                    setDiamondTabIconTrue('setting');
                }else{
                    setHeaderTabToSetting('setting');
                }
            }else if(URLPath.includes('#dyo')){
                if(URLPath.includes('diamondId')){
                    setDiamondTabIconTrue('dyo');
                }else{
                    setHeaderTabToSetting('dyo');
                }
            }
        }
    }

    useEffect(() => {
        const updatePathName:string[] = window.location.hash.split(/['/=']/);
        if(updatePathName){
            setCenterTabData(updatePathName);
        }
    }, [ring.options,diamond.details,footer.toggle]);

    const handleChangePath =useCallback((key:string)=>{
        setInitialOption();
        setInitialStartWithSettingData();
        const currentPathName:string[] = window.location.hash.split(/['/=']/);
        if(currentPathName.includes('#details')){
            setInitialDiamondOption();
        }
        if(key === 'dyo'){
            if(Object.keys(diamond.details).length > 0 && !currentPathName.includes('#details')){
                setDiamondTabIconTrue('dyo');
                // navigate(`/ring-builder?styleId=RB001&diamondId=${diamond.details.id}`);
                (screenManager as any).changeScreen({ viewName: 'dyo', id: diamond.details.id,styleId: 'RB001' });

            }else{
                setHeaderTabToSetting('dyo');
                // navigate('/ring-builder?styleId=RB001');
                (screenManager as any).changeScreen({ viewName: 'dyo', id: null,styleId: 'RB001' });
            }
        }else{
            if(Object.keys(diamond.details).length > 0 && !currentPathName.includes('#details')){
                setDiamondTabIconTrue('setting');
                // navigate(`/start-with-setting?diamondId=${diamond.details.id}`);
                (screenManager as any).changeScreen({ viewName: 'setting', id: diamond.details.id })
            }else{
                setHeaderTabToSetting('setting');
                // navigate('/start-with-setting');
                setIsShowFooter(false);
                (screenManager as any).changeScreen({ viewName: 'setting', id: null })
            }
            setInitialOption();
        }
    },[setHeaderTabToSetting,navigate,diamond])

    return {
        handleChangePath
    }
}

export default UseHeaderTab;