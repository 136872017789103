import React, {useCallback, useEffect, useRef, useState} from "react";
import {PropsDiamondFilter, PropsFromScreenManager, TableData, TabsProps} from "../../types";
import Table from "../Table";
import {
    Round,
    Asscher,
    Emerald,
    Heart,
    Oval,
    Pear,
    Princess,
    Radiant, Marquise, Elongated, Cushion,
} from "../Images";
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";
import {TooltipDataFetch} from "../Tooltip/TooltipDataFetch";
import {useMediaQuery} from "react-responsive";

export const source: string[] = ["Natural","Lab Grown"];
const color: string[] = ["J", "I", "H", "G", "F", "E", "D"];
const clarity: string[] = ["SI2", "SI1", "VS2", "VS1", "VVS2", "VVS1", "IF", "FL"];
const cut: string[] = ["Fair", "Good", "Very Good", "Excellent"];
const fluorecence: string[] = ["Very Strong", "Strong", "Medium", "Faint", "None"];
const polish: string[] = ["Fair", "Good", "Very Good", "Excellent"];
const symmetry: string[] = ["Fair", "Good", "Very Good", "Excellent"];
export const propertyMappings: Record<string, string[]> = {
    color: color,
    clarity: clarity,
    cut: cut,
    fluorecence: fluorecence,
    polish: polish,
    symmetry: symmetry,
};
export const shapes: Record<string, string> = {
    Round: Round,
    Emerald: Emerald,
    Oval: Oval,
    Pear: Pear,
    Radiant: Radiant,
    Princess: Princess,
    Marquise: Marquise,
    Elongated: Elongated,
    Cushion: Cushion,
    Asscher: Asscher,
    Heart: Heart
}
const useDiamond = ({
                        filter,
                        table,
                        tooltip,
                        tableDataLoading,
                        tableDataSuccess,
                        tableDataFail,
                        recommendedDataLoading,
                        recommendedDataSuccess,
                        recommendedDataFail,
                        setFilterMinMaxProperty,
                        setChangeMinMaxValue,
                        ring,
                        setInitialOption,
                        setBackDiamondDetails,
                        SetTooltipDataSuccess,
                        setTooltipDataLoading,
                        SetTooltipDataError,
                        resetFilters,
                        setInitialFilterAfterRingOptions,
                        instanceData,
                        setActiveTab,
                        setRecommendedDataDefault
                    }: PropsDiamondFilter & PropsFromScreenManager) => {
    useEffect(() => {
        getTableData();
    }, [filter.options])
    const [totalCount, setTotalCount] = useState<number>(0);
    const [tabs, setTabs] = useState<TabsProps[]>([])
    const hasEffectRun = useRef(false);
    const isMobileLayout = useMediaQuery({ query: "(max-width: 767px)" });
    const recommendData = useCallback(async () => {
        // const queryParams = new URLSearchParams(filter.options as Record<string, string>).toString();
        recommendedDataLoading();
        const queryParams = new URLSearchParams({shape:filter.options.shape,...(filter.options.source && { source: filter.options.source })} as Record<string, string>).toString();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}?filter=recommended&${queryParams}`);
            if (!response.ok) {
                recommendedDataFail(new Error(`HTTP error! Status: ${response.status}`));
            }
            const {data: result} = await response.json();
            result.forEach(updateTableItem);
            recommendedDataSuccess(result);
        } catch (e) {
            recommendedDataFail(e);
        }
    }, [filter.options, recommendedDataFail, recommendedDataSuccess, recommendedDataLoading, fetch]);

    const getTableData = useCallback(async () => {
        tableDataLoading();
        const queryParams = new URLSearchParams(filter.options as Record<string, string>).toString();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}?${queryParams}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const {data: result, minMax, total_count} = await response.json();
            tableDataSuccess(result);
            setTotalCount(total_count);
            result.forEach(updateTableItem);
            if (filter.isMinMaxChangeValue) {
                setFilterMinMaxProperty(minMax);
                setChangeMinMaxValue(false);
            }
        } catch (error) {
            console.error(error);
            tableDataFail(error);
        }
    }, [filter.isMinMaxChangeValue, setFilterMinMaxProperty, setChangeMinMaxValue, filter.options, fetch, tableDataLoading, tableDataFail, tableDataSuccess,setTotalCount]);

    const updateTableItem = (item: TableData) => {
        Object.keys(propertyMappings).forEach((property) => {
            if (item[property]) {
                item[property] = propertyMappings[property][item[property] as number - 1];
            }
        });
        /*Object.entries(item).forEach(([key,value]) => {
            item[key] = item[key] ? item[key] : '-'
        });*/

        if (typeof item.shape !== "boolean" && item.shape) {
            item.img = shapes[item.shape];
        }
        item.source = item.source === 'GNU' ? source[0] : source[1];
    };


    useEffect(() => {
        if(filter.options.shape && filter.options.source){
            recommendData();
        }else{
            setRecommendedDataDefault();
        }
        if (ring.isBackToDiamondSelection) {
            setInitialOption();
            setBackDiamondDetails(false);
        }
        (instanceData.screenManager as any).props.setStateManagerData(
          'currentScreen',
          'Diamond selection screen',
        );
        (instanceData.screenManager as any).props.setStateManagerData(
          'currentView',
          'Solitaire feed view',
        );
    }, [filter.options])

    const tabsData: TabsProps[] = [
        {
            label: `All (${(table && table.tableAllData && totalCount > 0) ? ToLocalStringConvert(+totalCount) : 0})`,
            content: <Table data={table.tableAllData} isLoading={table.isLoading} error={table.error} instanceData={instanceData}/>,
            isIcon: false,
        },
        {
            label: `Recommended (${table && table.recommendedData ? ToLocalStringConvert(+(table?.recommendedData.length)) : 0})`,
            content: <Table data={table.recommendedData} isLoading={table.recommendedLoading}
                            error={table.recommendedError} instanceData={instanceData}/>,
            isIcon: true,
            // iconName: "hk-sparkles"
        },{
            label: `Recent ${table && table.recentData && table.recentData.length > 0 ? `(${ToLocalStringConvert(+(table?.recentData.length))})` : ''}`,
            content: <Table data={table.recentData} isLoading={false} error={false} instanceData={instanceData}/>,
            isIcon: false,
            isNotShow:table.recentData.length < 1
        }
    ];

    useEffect(() => {
        const compareTab =
            {
                label: `Compare ${table && table.compareData && table.compareData.length > 0 ? `(${ToLocalStringConvert(+(table.compareData.length))})` : ''}`,
                content: <Table data={table.compareData} isLoading={false} error={false} instanceData={instanceData}/>,
                isIcon: false
            };

        if(table.compareData.length >0 ){
            setTabs([...tabsData,compareTab]);
        }else{
            setTabs(tabsData);
        }
        if((table.compareData.length === 0) && (table.activeTab === 3)){
            setActiveTab(0);
        }
    }, [table,totalCount])

    useEffect(()=>{
        if(!hasEffectRun.current){
            TooltipDataFetch(setTooltipDataLoading,SetTooltipDataSuccess,SetTooltipDataError);
        }
        return () => {
            hasEffectRun.current = true;
        }
    },[hasEffectRun])

    const handleRESETFilters = useCallback(() => {
        if (table.activeTab === 1) setActiveTab(0);
        if(Object.keys(filter.afterRingSelectedOptions).length > 0){
            setInitialFilterAfterRingOptions();
        }else {
            resetFilters()
        }
    }, [resetFilters,setActiveTab,table.activeTab,setInitialFilterAfterRingOptions,filter.afterRingSelectedOptions])


    return {
        tabs,
        color,
        clarity,
        cut,
        fluorecence,
        polish,
        symmetry,
        source,
        handleRESETFilters,
        isMobileLayout
    }
}
export default useDiamond;
