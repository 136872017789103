// reducers/counter.ts
import TableConstant from '../constant/TableConstant';
import {InitialTableState, TableAction, TableData} from "../types";

const initialTableState: InitialTableState = {
    tableAllData: [],
    recommendedData : [],
    recentData:[],
    compareData:[],
    recommendedLoading : false,
    recommendedError : '',
    isLoading: false,
    error: '',
    activeTab : 0,
    scrollToTop:false,
    expandRow : {
        0 : '',
        1: '',
        2: '',
        3:''
    }
}

const tableReducer = (state: InitialTableState = initialTableState, action:TableAction ): InitialTableState => {
    switch (action.type) {
        case TableConstant.RECOMMENDED_LOADING :
            return {
                ...state ,
                recommendedLoading: true
            }
        case TableConstant.TABLE_DATA_LOADING:
            return {
                ...state ,
                isLoading: true
            }
        case TableConstant.RECOMMENDED_DATA_SUCCESS:
            return {
                ...state ,
                recommendedLoading: false,
                error: "",
                recommendedData: action.payload as TableData[]
            }
        case TableConstant.TABLE_DATA_SUCCESS:
            return {
                ...state ,
                isLoading: false,
                error: "",
                tableAllData: action.payload as TableData[]
            }
        case TableConstant.RECENT_DATA_SUCCESS:
            const data = state.recentData.find(detail => detail.id === (action.payload as TableData).id);
            if(!data){
                return {
                    ...state ,
                    recentData:[action.payload as TableData,...state.recentData]
                }
            }
            return {
                ...state
            }
        case TableConstant.COMPARE_DATA_SUCCESS:
            const updateImage:any = action.payload;
            if(typeof updateImage.img === "object"){
                updateImage.img = updateImage.img.img
            }
            return {
                ...state ,
                compareData:[...state.compareData,updateImage as TableData]
            }
        case TableConstant.COMPARE_DATA_DELETE:
            let updatedList :TableData[] = [];
            if(state.compareData.length >0){
                updatedList = state.compareData.filter(detail => detail.id !== (action.payload as TableData).id);
            }
            return {
                ...state ,
                compareData:[...updatedList]
            }
        case TableConstant.TABLE_DATA_FAIL:
            return {
                ...state ,
                isLoading: false,
                tableAllData: [],
                error: action.payload as (Error | string)
            }
        case TableConstant.RECOMMENDED_FAIL:
            return {
                ...state ,
                recommendedLoading: false,
                recommendedData: [],
                recommendedError: action.payload as (Error | string)
            }
        case TableConstant.ACTIVE_TAB_INDEX:
            return {
                ...state ,
                activeTab : +action.payload as number
            }
        case TableConstant.EXPANDED_ROW_WITH_ACTIVE_TAB:
            const dummyKey :number = (action.payload as { key: number; value: string | number | undefined; }).key;
            const key: number = ((state.recentData.length === 0) && (dummyKey === 2)) ? (dummyKey + 1): dummyKey;
            const value: string | number | undefined = (action.payload as { key: number; value: string | number | undefined; }).value;
            return {
                ...state,
                expandRow: {
                    ...state.expandRow,
                    [key]: state.expandRow[key] === value ? '' : value
                }
            }
        case TableConstant.TOGGLE_SCROLL_TO_TOP:
            return {
             ...state,
             scrollToTop:action.payload as boolean
            }
        case TableConstant.SET_RECOMMENDED_DATA_DEFAULT:
            return {
                ...state,
                recommendedData:[]
            }
        case TableConstant.INITIAL_TABLE_STATE:
            return initialTableState;
        default:
            return state;
    }
};

export default tableReducer;
