import {extraDataType} from "../types";

export const getNoBands = async(extraData:extraDataType,setRingExtraData:(value:extraDataType)=>void,Ring_Style: string, Ring_Crown: string, Ring_Side: string) => {
    const prevExtraData = extraData;
    try {
        const getNoBandURL = process.env.REACT_APP_RING_PRICE;
        const bandSKU = `${Ring_Style}-${Ring_Crown}-${Ring_Side}`;
        const response = await fetch(`${getNoBandURL}?action=no_bands&sku=${bandSKU}`);
        if (!response.ok) {
            return ;
        }
        const res = await response.json();
        prevExtraData.Bands = res[bandSKU];
        setRingExtraData(prevExtraData);

        return res;
    }catch (error) {
        console.log(error,'error');
    }
}
