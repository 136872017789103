import {ColorImgType, SliderContent, TableData} from "../types";
import {
  Asscher,
  Cushion,
  Emerald,
  Marquise,
  Oval,
  Pear,
  Princess,
  Radiant,
  RoseGold,
  Round,
  SilverGold,
  YellowGold
} from "../components/Images";
import {useEffect} from "react";

export const colorName: { [key: string]: string } = {
  WG: 'White',
  YG: 'Yellow',
  RG: 'Rose',
};

export const ColorImg: ColorImgType = {
  "White": SilverGold,
  "Yellow": YellowGold,
  "Rose": RoseGold,
};

export const DiamondShapeContent: SliderContent[] = [
  {
    imgPath: Round,
    label: "Round",
    code: 'RND'
  },
  {
    imgPath: Princess,
    label: "Princess",
    code: 'PRC'
  },
  {
    imgPath: Emerald,
    label: "Emerald",
    code: 'EMR'
  },
  {
    imgPath: Oval,
    label: "Oval",
    code: 'OVA'
  },
  {
    imgPath: Pear,
    label: "Pear",
    code: 'PEA'
  },
  {
    imgPath: Radiant,
    label: "Radiant",
    code: 'RAD'
  },
  {
    imgPath: Marquise,
    label: "Marquise",
    code: 'MAR'
  },
  /*{
    imgPath: Cushion,
    label: "Cushion",
    code: 'CUS'
  },
  {
    imgPath: Asscher,
    label: "Asscher",
    code: 'ASC'
  },*/
  /*{
      imgPath: Heart,
      label: "Heart",
      code: 'HAT'
  }*/
];

export const DiamondLabels :{[key:string]:string} ={
  Round:"Round",
  Princess:"Princess-Cut",
  Pear:"Pear-Shaped",
  Oval:"Oval",
  Emerald:"Emerald-Cut",
  Radiant:"Radiant-Cut",
  Marquise:"Marquise-Cut",
  Cushion:"Cushion-Cut",
  Heart:"Heart-Shaped"
}
