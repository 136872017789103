export default {
    SET_FILTER : 'SET_FILTER',
    REMOVE_FILTER_KEY: 'REMOVE_FILTER_KEY',
    RESET_FILTER_KEY : 'RESET_FILTER_KEY',
    FILTER_MIN_MAX_PROPERTY : 'FILTER_MIN_MAX_PROPERTY',
    SET_MIN_MAX_VALUE_CHANGE: 'SET_MIN_MAX_VALUE_CHANGE',
    FILTER_INITIAL_STATE: 'FILTER_INITIAL_STATE',
    FILTER_INITIAL_STATE_AFTER_RING_OPTIONS: 'FILTER_INITIAL_STATE_AFTER_RING_OPTIONS',
    SET_AFTER_RING_OPTIONS: 'SET_AFTER_RING_OPTIONS'
};
