import DiamondConstant from "../constant/DiamondConstant";
import {TableData} from "../types";

export const setDiamondDetailsLoading = () => ({
    type: DiamondConstant.SELECT_DIAMOND_DETAILS_LOADING
});
export const setDiamondDetailsSuccess = (value: TableData) => ({
    type: DiamondConstant.SELECT_DIAMOND_DETAILS_SUCCESS,
    payload: value,
});
export const setDiamondDetailsFail = (error:unknown) => ({
    type: DiamondConstant.SELECT_DIAMOND_DETAILS_FAIL,
    payload : error
});
export const setInitialDiamondOption = () => ({
    type: DiamondConstant.SET_INITIAL_DIAMOND_OPTIONS,
});
export const setDiamondAvailable = (value:boolean) =>({
    type:DiamondConstant.SET_DIAMOND_AVAILABLE,
    payload:value
})