import {PDPBuilderType, PropsFromScreenManager, TableData} from "../../types";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {iFrameHost} from "../BuildOwn/BuildOwn";
import {useNavigate} from "react-router-dom";
import {propertyMappings, source} from "../Diamond/useDiamond";
import {buildYourOwnValue, findNearestValue, startWithSettingValue} from "../../Utils/findNearestCaratValue";
import {getDiamondCode} from "../../Utils/getDiamondCode";
import {DiamondLabels} from "../../Utils/commonData";
import {getNoBands} from "../../Utils/getNoBands";

const usePdpBuilder = (props: PDPBuilderType & PropsFromScreenManager) => {
  const {
    ring,
    diamond,
    footer,
    settingFilter,
    setSelectedRingDetails,
    setSelectedDiamondLoading,
    setSelectedDiamondData,
    setSelectedDiamondFail,
    setRingOptions,
    setOptionsLoading,
    setOptionsData,
    setOptionsFail,
    setDiamondDetailsLoading,
    setDiamondDetailsFail,
    setDiamondDetailsSuccess,
    SetTooltipDataSuccess,
    setTooltipDataLoading,
    SetTooltipDataError,
    setStyleID,
    setIframeLoadActionCount,
    setPriceLoading,
    setRingPrice,
    setRingPriceError,
    setRingExtraData,
    setBandTabIndex,
    setIsShowFooter,
    setFooterToggle,
    setAfterRingOptions,
    setSettingTabIconTrue,
    instanceData: {screenManager},
  } = props;

  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const match_styleId = currentUrl.match(/styleId=([^&]*)/);
  const match_diamondId = currentUrl.match(/diamondId=([^&]*)/);
  const styleId = match_styleId?.[1].split('/')[0] || '';
  const diamondId = match_diamondId?.[1] || '';
  const [showError, setShowError] = useState<boolean>(false);

  const [productName, setProductName] = useState<string>('')
  const hasEffectRun = useRef(false);
  const [fieldValidation, setFieldValidation] = useState<string[]>([])
  const rpid = localStorage.getItem('rpid');
  const builder_mode = localStorage.getItem('builder_mode');
  const uuid = localStorage.getItem('design_uuid');
  const [weddingBand,setWeddingBand] = useState<string[]>(['No Band','Single','Double'])
  // const weddingBand = ['No Band','Single','Double'];
  const {ring_price, left_band_price, right_band_price} = ring.ringPrice;
  const ringPrice = +ring_price /*+ (ring.options.Wedding_Band !== weddingBand[0] ? ring.options.Wedding_Band === weddingBand[1] ? left_band_price : (left_band_price+right_band_price)  :0)*/;

  useEffect(()=>{
    if(ring.extraData.Bands === 2 ){
      setWeddingBand(['No Band','Single','Double']);
    }else if(ring.extraData.Bands === 1){
      setWeddingBand(['No Band','Single']);
    }
  },[ring.extraData.Bands])

  const otherDialogOptions = {
    Views : [
      {
        Name : "Perspective (V1)",
        Code : "V1",
        isChecked : true
      },
      {
        Name : "Front (V2)",
        Code : "V2",
        isChecked : true
      },
      {
        Name : "Top (V3)",
        Code : "V3",
        isChecked : true
      },
      {
        Name : "Side (V4)",
        Code : "V4",
        isChecked : true
      },
      {
        Name : "Hand Light (V5)",
        Code : "V5",
        isChecked : true
      },
      {
        Name : "Hand Dark (V6)",
        Code : "V6",
        isChecked : true
      }
    ],
    Bands : [
      {
        Name : "Only Ring (C3)",
        Code : "C3",
        isChecked : true
      },
      {
        Name : "Ring + 1 Band (C2)",
        Code : "C2",
        isChecked : true
      },
      {
        Name : "Ring + 2 Band (C1)",
        Code : "C1",
        isChecked : true
      },
      {
        Name : "Only Band (C4)",
        Code : "C4",
        isChecked : true
      }
    ]
  }

  /*useEffect(() => {
    const {Diamond_Type, Diamond_Shape, Ring_Style, Color, Metal} = ring.selectedRingDetails;
    const {Label} = ring.extraData;
    let DiamondSize = ring.optionsData.Center_Diamond_Size && ring.optionsData.Center_Diamond_Size.find((size: any) => size.Code === ring.options.Center_Diamond_Size);
    let centerDiamondSize = (diamond.details && diamond.details.carat) ? `${diamond.details.carat} ct` : DiamondSize !== undefined ? `${DiamondSize.Code / 100} ct` : `${ring.options.Center_Diamond_Size} ct`;
    if (Object.keys(ring.selectedRingDetails).length > 0) {
      setProductName(`${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} ${Diamond_Type} Diamond ${DiamondLabels[Diamond_Shape as string]} Setting in ${(Metal === 'Platinum') ? Metal : `${Metal.split(' ')[0]} ${Color} ${Metal.split(' ')[1]}`} ${ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''}`)
    }
  }, [ring, ring.options, diamond, diamond.details])*/

  useEffect(() => {
    setStyleID(styleId as string)
  }, [styleId])

  /*useEffect(() => {
    Object.entries(ring.options).map(([key, value]) => {
      if (ring.optionsData) {
        const allValue = ring.optionsData[key];
        if (allValue) {
          const findSwatchValue = (allValue as allSwatchValue[]).find(
            (a) => a.Code === value
          );
          if (findSwatchValue) {
            setSelectedRingDetails(
              key,
              findSwatchValue.Name as string
            );
          }
        }
      }
    });
    if ("Engraving" in ring.options) {
      setSelectedRingDetails("Engraving", ring.options["Engraving"])
    }

  }, [ring.options]);*/
  useEffect(() => {
    if (Object.keys(ring.options).length > 0) {
      const {Ring_Style, Ring_Side, Color} = ring.options;
      if (Ring_Style && Ring_Side && Color) {
        const {Ring_Crown, Diamond_Shape, Center_Diamond_Size, Diamond_Type, Wedding_Band} = ring.options;
        // const ringPriceData = `${styleId}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}-${Metal}-${Color}-${Diamond_Type}`;
       /* const ringPriceData = `${styleId}-${Ring_Style}${Ring_Crown}-RND-200-${Ring_Side}-${Metal}-WG-${Diamond_Type}`;
        const priceData = {
          sku: `'${ringPriceData}'`,
          order_items: Wedding_Band === "No Band" ? 1 : Wedding_Band === "Single" ? 2 : 3
        }
        getRingPrice(setPriceLoading,setRingPrice,setRingPriceError, priceData);*/
        getNoBands(ring.extraData,setRingExtraData,Ring_Style,Ring_Crown,Ring_Side);
      }
    }
  }, [ring.options["Ring_Style"],ring.options["Ring_Crown"],ring.options["Diamond_Shape"],ring.options["Center_Diamond_Size"],ring.options["Ring_Side"],ring.options["Metal"],ring.options["Color"],ring.options["Diamond_Type"],ring.options["Wedding_Band"]])
  const [pendingEvents, setPendingEvents] = useState<{ [key: string]: string | boolean }[]>([]);
  const iframeAction = useRef<{ [key: string]: string | boolean }[]>(pendingEvents);

  useEffect(() => {
    iframeAction.current = pendingEvents;
  }, [pendingEvents])

  useEffect(() => {
    const afterDesignLoaded = ["ChangeRingColor", "LeftBand", "RightBand", "ChangeLeftBandColor", "ChangeRightBandColor"];
    let isHandShakeReturn = false;

    const handleMessageEvent = (event: MessageEvent<any>) => {
      //@ts-ignore
      const origin = event.origin || event?.originalEvent.origin;
      if (origin === window.location.origin) {
        switch (event.data.action) {
          case  'GetRenderOptions' :
            if (event.data.show) {
              setIsOpenDialog(true);
            } else {
              handleDialogAction();
            }
            break;
          case  'HandshakeIntegration' :
            window.postMessage({
              action: "HandshakeInternal"
            });
            break;
          case  'HandshakeReturnInternal' :
            isHandShakeReturn = true;
            break;
        }
        if (isHandShakeReturn) {
          iframeAction.current.filter(model => !afterDesignLoaded.includes(model.action as string)).forEach((data) => {
            window.postMessage(data);
          });
          isHandShakeReturn = false;
        }
      }
      if (origin === iFrameHost) {
        if (event.data.action === 'DesignLoaded') {
          afterDesignLoadEvent(iframeAction,afterDesignLoaded)
        }
      }
      if(event.data.action === 'DesignLoadError'){
        afterDesignLoadEvent(iframeAction, afterDesignLoaded)
      }
      /*if(event.data.action === "DownloadFile"){
        set3DImageSuccess(event.data.imageUrl);
      }*/
    };
    if (iframeAction.current.length > 0) {
      // console.log(iframeAction.current)
      window.addEventListener("message", handleMessageEvent);
      window.postMessage({
        action: "HandshakeInternal"
      });
    }
    return () => {
      if (iframeAction.current.length > 0) {
        // console.log("REMOVE EVENT LISTENER")
        window.removeEventListener("message", handleMessageEvent);
      }
    };
  }, [pendingEvents]);
  const afterDesignLoadEvent = (iframeAction: React.MutableRefObject<{ [p: string]: string | boolean }[]>, afterDesignLoaded: string[]) => {
    setIframeLoadActionCount(count => {
      const updatedCount = count - 1;
      if (updatedCount === 0) {
        iframeAction.current.filter(model => afterDesignLoaded.includes(model.action as string)).forEach((data) => {
          window.postMessage(data);
        });
        window.postMessage({
          action: 'SwitchCameraView'
        })
      }
      return updatedCount;
    });
  }
  const handle3DObjectView = useCallback((key: string, value: string | boolean) => {
    setPendingEvents((prev) => {
      // Create a new array with the updated value
      const updatedPendingEvents = [...prev, {action: key, value}];
      // Update the iframeAction.current ref with the new array reference
      // iframeAction.current = updatedPendingEvents;
      return updatedPendingEvents;
    });

  }, [setPendingEvents]);
  const [isPresetStyle,setIsPresetStyle] = useState<boolean>(false);
  const optionsData = useCallback(async (selectedDiamond: { [key: string]: string }, shape: string | null) => {
    setOptionsLoading();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PDP_OPTIONS_KEY}?ring_builder=${styleId}`
      );
      if (!response.ok) {
        setOptionsFail(new Error(`HTTP error! Status: ${response.status}`));
      }
      const result = await response.json();
      const {
        Diamond_Type,
        Diamond_Shape,
        Ring_Crown,
        Ring_Side,
        Ring_Style,
        Color,
        Metal,
        Ring_Size,
        Center_Diamond_Size,
        Extra_Data
      } = result;
      setRingExtraData(Extra_Data);
      const newResult = {
        Ring_Style,
        Diamond_Shape,
        // Diamond_Type,
        Center_Diamond_Size,
        Ring_Crown,
        Ring_Side,
        Color,
        // Metal,
        // Ring_Size
      };
      // console.log(Ring_Style,'ring_style');
      setOptionsData(newResult);
      const modelInitialData: { [key: string]: string } = {};
      const isPreset = Ring_Style.length === 1 && Ring_Crown.length === 1 && Ring_Side.length === 1 ;
      setIsPresetStyle(isPreset)
        setRingDialogOptions((prev:any)=>{
          return { ...prev , isPresetStyle: isPreset}
        })
      if ((!(rpid && uuid) && builder_mode !== "edit") || !(Object.keys(ring.options).length > 0)) {
        Object.entries(newResult).forEach(([key, value]) => {
          if (["Diamond_Shape", "Center_Diamond_Size", "Color"].includes(key)) {
            setRingDialogOptions((prev: any) => {
              return {...prev, [key]: value.map((v: any) => v.Code)}
            });
          }else {
            setRingDialogOptions((prev: any) => {
              return {...prev, [key]: isPreset ?  value.map((v: any) => v.Code) :[]}
            });
          }
          if (["Ring_Style", "Ring_Crown", "Center_Diamond_Size", "Ring_Side"].includes(key)) {
            modelInitialData[`Initial_${key}`] = key === "Center_Diamond_Size"
              ? (selectedDiamond["Center_Diamond_Size"] || (styleId.includes('RP') ? ring.options[key] || settingFilter.options[key] || "200" : ring.options[key] || value[0].Code))
              : (ring.options[key] || settingFilter.options[key] || value[0].Code)
          }
          if (key !== "Ring_Size") {
            switch (key) {
              case "Color":
                handle3DObjectView('ChangeRingColor', ring.options[key] || settingFilter.swatchColor ||settingFilter.options[key] || settingFilter.productOptions[key] || value[0].Code);
                break;
              case 'Diamond_Shape':
                modelInitialData[`Initial_${key}`] = ring.options[key] || selectedDiamond[key] || settingFilter.options[key] || settingFilter.productOptions[key] || value[0].Code;
                break;
            }
            if (!Object.keys(selectedDiamond).includes(key)) {
              const defaultValue =
                key === "Center_Diamond_Size"
                  ? (styleId.includes('RP') ? ring.options[Center_Diamond_Size] || settingFilter.options.Center_Diamond_Size || "200" : value[0].Code)
                  : key === "Color" ? (ring.options["Color"] || settingFilter.swatchColor || settingFilter.options["Color"] || settingFilter.productOptions[key] || value[0].Code)
                  : key === "Diamond Shape" ? (ring.options["Diamond Shape"] || settingFilter.options["Diamond Shape"] || settingFilter.productOptions["Diamond Shape"] || value[0].Code)
                  : Object.keys(settingFilter.options).includes(key)
                    ? (key === 'Ring_Style')
                            ? value[0].Code
                            :ring.options[key] || settingFilter.options[key]
                        : (key === 'Diamond_Type' && Object.keys(settingFilter.options).includes('Center_Diamond_Type'))
                        ? settingFilter.options['Center_Diamond_Type']
                        : value[0].Code;
              return setRingOptions(key, defaultValue);
            }
          }
        });
        Object.entries(otherDialogOptions).forEach(([key,value]) => {
          setRingDialogOptions((prev: any) => {
            const updatedOptions = value
              .filter((v) => v.isChecked)
              .map((v) => v.Code);
            return { ...prev, [key]: updatedOptions };
          })
        })
        const {
          Initial_Ring_Style,
          Initial_Diamond_Shape,
          Initial_Center_Diamond_Size,
          Initial_Ring_Crown,
          Initial_Ring_Side,
        } = modelInitialData;
        handle3DObjectView('LoadHead', `${styleId}/${styleId?.includes('RB') ? `${Initial_Ring_Style}${Initial_Ring_Crown}` : Initial_Ring_Crown}/${Initial_Diamond_Shape}/${Initial_Center_Diamond_Size}${styleId.includes('RP') ? `/${Initial_Ring_Side}` : ''}/head`);
        handle3DObjectView('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${Initial_Ring_Style}${Initial_Ring_Crown}` : Initial_Ring_Crown}/${Initial_Diamond_Shape}/${Initial_Center_Diamond_Size}/${Initial_Ring_Side}/shank`);
        if (Extra_Data && Extra_Data?.Bands) {
          handle3DObjectView('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Initial_Ring_Style}${Initial_Ring_Crown}` : Initial_Ring_Crown}/${Initial_Diamond_Shape}/${Initial_Center_Diamond_Size}/${Initial_Ring_Side}/bands`);
          setIframeLoadActionCount(count => count + 1);
        }
        setIframeLoadActionCount(count => count + 2);
        if (!footer.isRedirectToEdit) {
          setRingOptions('Wedding_Band', 'No Band');
        }
      }
    } catch (e) {
      setOptionsFail(e);
    }
  }, [setOptionsLoading, setOptionsFail, setOptionsData, fetch, setRingOptions, handle3DObjectView,settingFilter.options,settingFilter.productOptions]);

  const handlePDPOptions = useCallback((key: string, value: string) => {
    setRingOptions(key, value);
  }, [setRingOptions]);

  const selectedData = useCallback(async (diamondDetail: { [key: string]: string }, carat: string) => {
    const result = await getDiamondCode(diamondDetail, setSelectedDiamondLoading, setSelectedDiamondFail);
    if (result) {
      setSelectedDiamondData({...result, Center_Diamond_Size: findNearestValue(+carat)});
      optionsData({...result, Center_Diamond_Size: findNearestValue(+carat)}, diamondDetail.shape);
    }
  }, [URLSearchParams, setSelectedDiamondLoading, setSelectedDiamondData, setSelectedDiamondFail, optionsData, fetch]);
  const DetailsData = useCallback(async (id: string) => {
    setDiamondDetailsLoading();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/?id='${id}'`);
      if (!response.ok) {
        setDiamondDetailsFail(new Error(`HTTP error! Status: ${response.status}`));
      }
      const result: TableData = await response.json();

      Object.keys(propertyMappings).forEach((property: string) => {
        result[property] = propertyMappings[property][result[property] as number - 1];
      });
      const updatedResult: TableData = {
        ...result,
        source: result.source === 'GNU' ? source[0] : source[1],
        // diamond_price: result.diamond_price !== null ? `${result.diamond_price}` : '-'
      }
      setDiamondDetailsSuccess(updatedResult);
      setIsShowFooter(true);
      if (updatedResult) {
        const {shape, source, carat} = updatedResult;
        const details = {shape: shape as string, type: source as string};
        selectedData(details, carat as string);
      }
    } catch (e) {
      setDiamondDetailsFail(e);
    }
  }, [diamondId, setDiamondDetailsSuccess, setDiamondDetailsLoading, setDiamondDetailsFail, selectedData, fetch]);
  const startRendering = useRef<any>(null);
  const handleAllOptions = useCallback((key: string, value: any) => {
    const {
      /* Center_Diamond_Size,
       Diamond_Shape,
       Ring_Crown,
       Ring_Side,
       Ring_Style,*/
      Wedding_Band
    } = ring.options;
    // console.log(ring.options)
    setShowError(false);
    if (key === "Metal" && value.Code === "PT") {
      handlePDPOptions('Color', 'WG');
      if (Wedding_Band === "Single") {
        handlePDPOptions(`${Wedding_Band}_First_Band`, 'WG');
      }
      if (Wedding_Band === "Double") {
        handlePDPOptions(`${Wedding_Band}_First_Band`, 'WG');
        handlePDPOptions(`${Wedding_Band}_Second_Band`, 'WG');
      }
    }
    if (!["Diamond_Type", "Ring_Size"].includes(key)) {
      startRendering.current = key ;
    }
    handlePDPOptions(key, value.Code);
  }, [handlePDPOptions, ring.options, setShowError, startRendering.current]);
  const bands3DModel = useCallback(() => {
    const {Color, Wedding_Band, Single_First_Band, Double_First_Band, Double_Second_Band} = ring.options;
    const pendingValues: { [key: string]: boolean | string } = {};
    if (Color) pendingValues['ChangeRingColor'] = Color;
    if (Wedding_Band !== 'No Band') {
      if (Wedding_Band === "Single" || Wedding_Band === "Double") {
        pendingValues['ChangeLeftBandColor'] = Wedding_Band === "Single" ? Single_First_Band : Double_First_Band;
        pendingValues['LeftBand'] = true;
        pendingValues['RightBand'] = false;
        if (Wedding_Band === "Double") {
          pendingValues['ChangeRightBandColor'] = Double_Second_Band;
          pendingValues['RightBand'] = true;
        }
      }
    } else {
      ['LeftBand','RightBand'].forEach(band => pendingValues[band] = false);
    }
    const pendingRingSideEvents: {
      [key: string]: boolean | string
    }[] = Object.entries(pendingValues).map(([key, value]) => {
      return {action: key, value}
    })
    setPendingEvents(pendingRingSideEvents)
  }, [ring.options, setPendingEvents])

  function sendMessage(action: string, value: string | boolean) {
    window.postMessage({action, value});
  }

  const isEditableRingOptions = useRef(false);
  useEffect(() => {
    //edit code
    if (Object.keys(ring.options).length > 0) {
      isEditableRingOptions.current = true;
      const {
        Ring_Style,
        Ring_Crown,
        Diamond_Shape,
        Center_Diamond_Size,
        Ring_Side,
        Wedding_Band,
        Single_First_Band,
        Double_First_Band,
        Double_Second_Band,
        First_Band_Engraving,
        Second_Band_Engraving,
        Engraving,
        Color
      } = ring.options;

      const styleIdPrefix = styleId && styleId.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown;
      const bandTabIndex = Wedding_Band === 'Single' ? 1 : Wedding_Band === 'Double' ? 2 : 0;
      const bandColor = Wedding_Band === 'Single' ? Single_First_Band : Wedding_Band === 'Double' ? Double_First_Band : null;

      const pendingValues: { [key: string]: boolean | string } = {
        'LoadHead': `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}${styleId.includes('RP') ? `/${Ring_Side}` : ''}/head`,
        'LoadShank': `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/shank`,
        'ChangeRingColor': Color
      };
      if(ring.extraData.Bands !== 0){
        pendingValues['LoadBand']= `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`;
        setIframeLoadActionCount(count => count + 1);
      }
      if (Wedding_Band !== 'No Band') {
        if (Wedding_Band === "Single" || Wedding_Band === "Double") {
          setBandTabIndex(bandTabIndex);
          pendingValues['ChangeLeftBandColor'] = bandColor as string;
          pendingValues['LeftBand'] = true;
          if (Wedding_Band === "Double") {
            setBandTabIndex(bandTabIndex);
            pendingValues['ChangeRightBandColor'] = Double_Second_Band;
            pendingValues['RightBand'] = true;
          }
        }
      }

      if (Engraving) pendingValues['ShankEngraving'] = Engraving;
      if (First_Band_Engraving) pendingValues['LeftEngraving'] = First_Band_Engraving;
      if (Second_Band_Engraving) pendingValues['RightEngraving'] = Second_Band_Engraving;

      setIframeLoadActionCount(count => count + 2);
      const pendingRingSideEvents = Object.entries(pendingValues).map(([action, value]) => ({action, value}));
      setPendingEvents(pendingRingSideEvents);
      if (!(rpid && uuid) && builder_mode !== "edit") {
        return;
      }
    }

    if (Object.keys(diamond.details).length > 0) {
      const {shape, source, carat} = diamond.details;
      const details = {shape: shape as string, type: source as string};
      selectedData(details, carat as string);
    } else if (diamondId) {
      DetailsData(diamondId);
    } else {
      optionsData({}, null);
    }
  }, []);

  /*useEffect(() => {
    if (!hasEffectRun.current) {
      TooltipDataFetch(setTooltipDataLoading, SetTooltipDataSuccess, SetTooltipDataError);
    }
    return () => {
      hasEffectRun.current = true;
    }
  }, [])*/

  useEffect(() => {
    const {Center_Diamond_Size, Diamond_Shape, Ring_Crown, Ring_Side, Ring_Style, Wedding_Band} = ring.options || {};
    const messages = ["LeftBand", "RightBand"];
    if(Wedding_Band && !isEditableRingOptions.current){
      if (Wedding_Band !== 'No Band' && !footer.isRedirectToEdit && ring.extraData.Bands !== 0) {
        setIframeLoadActionCount(count => count + 1);
        sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`);
        bands3DModel();
      } else {
        messages.forEach(message => window.postMessage({action: message, value: false}));
      }
    }
  }, [ring.options["Wedding_Band"]]);

  const validate = useRef(false);

  /*useEffect(() => {
    if (fieldValidation.length > 0) {
      handleCheckValidation();
    }
  }, [ring.options])*/

  /*const handleCheckValidation = useCallback(() => {
    const fieldObject = Object.keys(ring.optionsData);
    setFieldValidation([]);
    fieldObject.forEach((field: string) => {
      const checkData = !Object.keys(ring.options).includes(field);
      if (checkData) {
        validate.current = true;
        setFieldValidation((prev) => {
          return [...prev, field];
        })
      }
    })
  }, [setFieldValidation, ring.optionsData, ring.options])*/
  /*useEffect(() => {
    if (showError) {
      document.querySelector(`button[name=${fieldValidation[0]}]`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }, [fieldValidation])*/
  const handleSelectSetting = useCallback(() => {
    setShowError(true);
    // handleCheckValidation();
    if (validate.current) {
      validate.current = false;
      return;
    }
    /* window.postMessage({action: "DownloadSnapshot"});
     set3DImageLoading();*/
    setIsShowFooter(true);
    if (
      screenManager &&
      (screenManager as any).changeScreen
    ) {
      (screenManager as any).props.setStateManagerData(
        'selectedSetting',
        ring.options,
      );

      if (Object.keys(diamond.details).length > 0) {
        (screenManager as any).props.setStateManagerData(
          'cost',
          (diamond.details.b2c_price as number) + +ringPrice,
        );

        // await storeDesignData();

        return setFooterToggle();
      }
      if (ring.options) {
        const {
          Diamond_Type,
          Diamond_Shape,
          Center_Diamond_Size,
        } = ring.options;
        const findNearestValue = styleId.includes('RP') ? startWithSettingValue : buildYourOwnValue;
        const selectedDiamondOptions = {
          source: Diamond_Type,
          shape: Diamond_Shape,
          carat: `${
            Number(findNearestValue[Center_Diamond_Size]) -
            Number(findNearestValue[Center_Diamond_Size]) * 0.1
          }-${
            Number(findNearestValue[Center_Diamond_Size]) +
            Number(findNearestValue[Center_Diamond_Size]) * 0.1
            // Number(findNearestValue[Center_Diamond_Size]) * (findNearestValue[Center_Diamond_Size] === 3 ? 1 : 0.1)
          }`,
        };
        setAfterRingOptions(selectedDiamondOptions);
      }
      setSettingTabIconTrue();
      // navigate('/select-diamond');
      (screenManager as any).changeScreen({
        viewName: 'diamond',
        id: null,
      });
    }
    /*if(Object.keys(diamond.details).length > 0){
            return setFooterToggle();
        }
        if(ring.options){
            const {Diamond_Type,Diamond_Shape,Center_Diamond_Size} = ring.options;
            const selectedDiamondOptions = {
                source: Diamond_Type,
                shape: [Diamond_Shape],
                carat: `${Number(centerDiamondSizeValue[Center_Diamond_Size]) - Number(centerDiamondSizeValue[Center_Diamond_Size])*0.1}-${Number(centerDiamondSizeValue[Center_Diamond_Size]) + Number(centerDiamondSizeValue[Center_Diamond_Size])*0.1}`
            };
            setAfterRingOptions(selectedDiamondOptions)
        }
        setSettingTabIconTrue();
        navigate('/select-diamond');
    }}*/
  }, [screenManager, setSettingTabIconTrue, setAfterRingOptions, setRingOptions, ring.options, setIsShowFooter]);

  /*useEffect(() => {
    if (["Jenny Packham", "Reve"].includes(ring.extraData.Label)) {
      setRingOptions('Diamond_Type', 'LGN');
    } else if (["Lotus"].includes(ring.extraData.Label)) {
      setRingOptions('Diamond_Type', 'GNU');
    }
  }, [ring.options["Diamond_Type"]])*/
  useEffect(() => {
    const {
      Center_Diamond_Size,
      Diamond_Shape,
      Ring_Crown,
      Ring_Side,
      Ring_Style,
      Wedding_Band,
      Metal,
      Color
    } = ring.options;
    if (startRendering.current) {
      if (!["Color", "Metal"].includes( startRendering.current)) {
        if ( startRendering.current !== "Ring_Side") {
          sendMessage('LoadHead', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}${styleId.includes('RP') ? `/${Ring_Side}` : ''}/head`);
          setIframeLoadActionCount(count => count + 1);
        }
        sendMessage('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/shank`);
        setIframeLoadActionCount(count => count + 1);
        if (Wedding_Band !== 'No Band' && ring.extraData.Bands !== 0) {
          sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`);
          setIframeLoadActionCount(count => count + 1);
        }
        bands3DModel();
      } else {
        if (startRendering.current === "Color") {
          // console.log(ring.options)
          sendMessage('ChangeRingColor', Color);
        } else {
          if (Metal === "PT") {
            sendMessage('ChangeRingColor', 'WG');
            if (Wedding_Band === "Single" || Wedding_Band === "Double") {
              sendMessage('ChangeLeftBandColor', 'WG');
            }
            if (Wedding_Band === "Double") {
              sendMessage('ChangeRightBandColor', 'WG');
            }
          }
        }
      }
      startRendering.current = null ;
    }
  }, [ring.options["Ring_Style"], ring.options["Diamond_Shape"], ring.options["Center_Diamond_Size"], ring.options["Ring_Crown"], ring.options["Ring_Side"], ring.options["Color"], ring.options["Metal"], startRendering.current]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const closeDialogBox = useCallback(() => {
    setIsOpenDialog(false);
  }, [setIsOpenDialog])

  const openDialogBox = useCallback(() => {
    setIsOpenDialog(true);
  }, [setIsOpenDialog])
  const [ringDialogOptions, setRingDialogOptions] = useState<any>([]);
  const handleDialogAction = () => {
    window.postMessage({
      action: 'PrepareRender',
      options: ringDialogOptions,
    }, '*');
    setIsOpenDialog(false);
  }
  const handleDialogCheck = (e: any, key: string) => {
    setRingDialogOptions((prev: any) => {
      const newOptions = {...prev};
      // @ts-ignore
      if (!(key in newOptions)) {
        newOptions[key] = [];
      }
      if (e.target.checked) {
        newOptions[key] = [...newOptions[key], e.target.value]
      } else {
        if (newOptions[key] && newOptions[key].length > 0) {
          newOptions[key] = newOptions[key].filter((code: string) => code !== e.target.value);
        }
      }
      return newOptions;
    })
  }
  return {
    rpid,
    uuid,
    builder_mode,
    handlePDPOptions,
    handle3DObjectView,
    navigate,
    productName,
    handleAllOptions,
    styleId,
    handleSelectSetting,
    fieldValidation,
    isEditableRingOptions,
    weddingBand,
    ringPrice,
    setShowError,
    isOpenDialog,
    closeDialogBox,
    openDialogBox,
    setRingDialogOptions,
    handleDialogCheck,
    handleDialogAction,
    ringDialogOptions,
    otherDialogOptions
  }
};
export default usePdpBuilder;
