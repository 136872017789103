import React, {FC} from "react";
import Button from "../Button/Button";
import classes from "./Filter.module.css";
import useFilter from "./useFilter";
import Icon from "../Icons/Icon";
import {RootState} from "../../reducers/RootReducer";
import {resetFilters,setInitialFilterAfterRingOptions} from "../../action/FilterAction";
import {connect, ConnectedProps} from "react-redux";
import {FilterProps} from "../../types";

const mapStateToProps = (state: RootState) => ({
  filter: state.filter,
  footer:state.footer
});

const mapDispatchToProps = {
  resetFilters,
  setInitialFilterAfterRingOptions
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromFilterRedux = ConnectedProps<typeof connector>;

const Filter: FC<FilterProps> = ({isShowFilter,handleRESETFilters,resetFilters,isDisabledFilter,isReset,children,isSetHeight,filter,setInitialFilterAfterRingOptions,footer,isMobileLayout}) => {
  const {
    height,
    handleFilters,
    ref,
    defaultHeight,
    toggleFilter,
    isLargeTablet
  } = useFilter({isSetHeight,footer});
  
  return (
    <>
      {isShowFilter ?
          <div
            className={`${
            isLargeTablet ? classes.main_wrp_mobile : classes.main_wrp
            }
            ${!toggleFilter ? "" : classes.isActive}`}
            id={'filterMain'}
          >
            <div
              style={{ height }}
              className={`${classes.main} ${!toggleFilter ? "" : classes.isOpen}`}
            >
            <span onClick={handleFilters} className={classes.overlay}/>
            <div className={classes.root} ref={ref}>
              <div className={classes.container}>
                {children}
              </div>
              {isMobileLayout ? <div className={`${classes.items} mt-auto`}><Button type="button" onClick={handleFilters} className={classes.done} buttonType="btnPrimary">Done</Button></div> : ''}
            </div>
          </div>
            <div className={classes.filtersButton_wrap}>
              {+defaultHeight <= +height || isLargeTablet  ?
                <Button
                  type={"button"}
                  className={classes.filtersButton}
                  onClick={handleFilters}
                  isDisabled={isDisabledFilter}
                >
                  {/*<Icon name={'hk-Filter'} />*/}
                  <span>{!toggleFilter ? 'More Filters' : 'Less Filters'}</span>
                  <Icon name={toggleFilter ? "hk_chevron_up" : "hk_chevron_down"} />
                </Button>
              :
                  null
              }
              <Button
                type={"button"}
                className={
                  `${classes.filterResetButton}
                   ${isReset ? classes.activeReset : ''}
                `}
                onClick={handleRESETFilters}
              >
                <span className="text-padding-top">Reset</span>
              </Button>
            </div>
          </div>
      :
          <div className={classes.filterLoading}/>
      }
    </>
  );
}
export default connector(Filter);
