import {CartAction, CartState} from "../types";
import CartConstant from "../constant/CartConstant";

const initialCartState: CartState = {
  isLoading: false,
  cartImage: [],
  image:[],
  cartThumbImage:[]
};

const cartReducer = (state = initialCartState, action: CartAction) :CartState => {
  switch (action.type) {
    case CartConstant.THREE_D_IMAGE_LOADING:
      return {
        ...state,
        isLoading : true,
        cartImage: []
      };
    case CartConstant.THREE_D_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading : false,
        cartImage : [action.payload as string]
      }
    case CartConstant.CART_IMAGE_SUCCESS:
      return {
        ...state,
        image : action.payload as string[]
      }
    case CartConstant.CART_THUMB_IMAGE_SUCCESS:
      return {
        ...state,
        cartThumbImage : action.payload as string[]
      }
    case CartConstant.SET_INITIAL_CART:
      return initialCartState;
    default:
      return state;
  }
}
export default cartReducer;
