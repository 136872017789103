import {useCallback, useState} from "react";
import {setFilterType} from "../../types";
import {getRingPrice} from "../../Utils/getRingPriceValue";

const tooltipPadding ={
    carat: '10px 0px',
    color: '1rem 0px',
    clarity:'1rem 0px'
}

const useDiamondFilterContent = ({ring,footer,setPriceLoading,setRingPrice,setRingPriceError,setFilter,removeFilterKey,setRingOptions}:{ring:any,footer:any,removeFilterKey:any,setFilter:setFilterType,setRingOptions:any,setPriceLoading:any,setRingPrice:any,setRingPriceError:any}) => {
    const [SCS,setSCS] = useState<boolean>(false);
    const handleFilterType = useCallback((value: string | string[] | boolean, key: string) => {
        if(key === "scs" && !value){
            return removeFilterKey('scs');
        }
        setFilter(key, value);
        if(["source"].includes(key) && Object.keys(ring.options).length > 0){
            setRingOptions("Diamond_Type" , value);
            const {Ring_Crown,Ring_Style,Ring_Side,Diamond_Shape,Center_Diamond_Size,Metal,Color,Diamond_Type,Wedding_Band} = ring.options;
            const ringPriceData = `${footer.styleId}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}-${Metal}-${Color}-${Diamond_Type}`;
            const priceData = {
                sku: `'${ringPriceData}'` ,
                order_items: Wedding_Band === "No Band" ? 1 : Wedding_Band === "Single" ? 2 : 3
            }
            getRingPrice(setPriceLoading,setRingPrice,setRingPriceError,priceData);
        }
    },[setFilter,setRingOptions,setPriceLoading,setRingPrice,setRingPriceError]);

    return {
        handleFilterType,
        SCS,
        setSCS,
        tooltipPadding
    }
}
export default useDiamondFilterContent;
