import TableConstant from '../constant/TableConstant';
import {TableData} from "../types";

export const tableDataLoading = () => ({
    type: TableConstant.TABLE_DATA_LOADING
});
export const tableDataSuccess = (val:TableData[]) => ({
    type: TableConstant.TABLE_DATA_SUCCESS,
    payload: val,
});
export const tableDataFail = (error:unknown) => ({
    type: TableConstant.TABLE_DATA_FAIL,
    payload : error
});

export const recommendedDataLoading = () => ({
    type: TableConstant.RECOMMENDED_LOADING
});
export const recommendedDataSuccess = (val:TableData[]) => ({
    type: TableConstant.RECOMMENDED_DATA_SUCCESS,
    payload: val,
});
export const recommendedDataFail = (error:unknown) => ({
    type: TableConstant.RECOMMENDED_FAIL,
    payload : error
});
export const  setRecommendedDataDefault = ()=>({
    type:TableConstant.SET_RECOMMENDED_DATA_DEFAULT
})

export const recentDataSuccess = (val:TableData) => ({
    type: TableConstant.RECENT_DATA_SUCCESS,
    payload: val,
})

export const setActiveTab = (index:number) => ({
    type: TableConstant.ACTIVE_TAB_INDEX,
    payload: index,
})

export const setExpandedRow = (key: number, value: string | number) => ({
    type: TableConstant.EXPANDED_ROW_WITH_ACTIVE_TAB,
    payload: { key, value },
});

export const setTableInitialState = () => ({
    type: TableConstant.INITIAL_TABLE_STATE
});

export const compareDataSuccess = (value:TableData) => ({
    type: TableConstant.COMPARE_DATA_SUCCESS,
    payload:value
})

export const removeCompareData = (value:TableData) => ({
    type: TableConstant.COMPARE_DATA_DELETE,
    payload:value
})

export const toggleScrollTop = (value:boolean)=>({
    type:TableConstant.TOGGLE_SCROLL_TO_TOP,
    payload:value
})